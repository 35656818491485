<template>
    <o-col-container>
      
        <div class="row-container">
            <ODataGrid 
                :dataObject="versionsCompare.dsSource1"
                hideGridMenu
                disableFilterRow
                hideMultiselectColumn
                
                activeRows
                hideActionColumn
                :rowclickhandler="(row)=>versionChange1(row)">

                <OColumn field="Version" disableSortable/>
                <OColumn v-if="type != 'DBObject'" field="CreatedBy" disableSortable/>
                <OColumn v-if="type == 'DBObject'" field="CreatedByName" disableSortable/>
                <OColumn v-if="type != 'DBObject'" field="PublishDescription" disableSortable :cellTitle="row => row.PublishDescription"/>
                <OColumn v-if="type == 'DBObject'" field="Description" disableSortable :cellTitle="row => row.Description"/>
                <OColumn v-if="type != 'DBObject'" field="Timestamp" format="General Date Short Time" disableSortable/>
                <OColumn v-if="type == 'DBObject'" field="Updated" format="General Date Short Time" disableSortable/>
                
        
            </ODataGrid>
        </div>
        <o-sizer-panel width="50%" id="id_versionToCompare">
             <ODataGrid 
                class="ps-2"
                :dataObject="versionsCompare.dsSource2"
                hideGridMenu
                disableFilterRow
                activeRows
                hideMultiselectColumn
                :rowclickhandler="(row)=>versionChange2(row)"
                
                hideActionColumn>

                <OColumn field="Version" disableSortable/>
                <OColumn v-if="type != 'DBObject'" field="CreatedBy" disableSortable/>
                <OColumn v-if="type == 'DBObject'" field="CreatedByName" disableSortable/>
                <OColumn v-if="type != 'DBObject'" field="PublishDescription" disableSortable :cellTitle="row => row.PublishDescription"/>
                <OColumn v-if="type == 'DBObject'" field="Description" disableSortable :cellTitle="row => row.Description"/>
                <OColumn v-if="type != 'DBObject'" field="Timestamp" format="General Date Short Time" disableSortable/>
                <OColumn v-if="type == 'DBObject'" field="Updated" format="General Date Short Time" disableSortable/>

            </ODataGrid>
        </o-sizer-panel>
    </o-col-container>
</template>

<script setup lang="ts">
 
    import {getVersionsCompare} from "o365.modules.VersionsCompare.ts";

    const emit = defineEmits(['sourceChange']);
    
    const props = defineProps({
        fileName:{
            type:String
        },type:{
            type:String,
            default:"files"
        },versionsCompare:{
            type:Object
        },
        currentVersion: {
            type: Number,
            default: 0
        }  
    });
    console.log(props.type)

    const versionsCompare = props.versionsCompare?? getVersionsCompare(props.fileName,props.type,props.currentVersion);
    versionsCompare.initDataObjects().then(()=>{
        emit("sourceChange");
    });

    const versionChange1 = (pRow)=>{
        versionsCompare.dsSource1.setCurrentIndex(pRow.index);
        emit("sourceChange");
    }
    const versionChange2 = (pRow)=>{
        //const vRowKey = pRow.RowKey;
        
        let vCurrentVersion = versionsCompare.dsSource1.current.Version;

        var vIndex = versionsCompare.dsSource1.data.findIndex(x=>x.Version === vCurrentVersion - 1);
        versionsCompare.dsSource1.setCurrentIndex(vIndex)

        versionsCompare.dsSource1.filterObject.updateItem({
            column:'Version',
            operator:'lessthan',
            value:pRow.Version
        });
        versionsCompare.dsSource1.filterObject.apply();
        versionsCompare.dsSource1.load().then(d=>{
            var vIndex = d.findIndex(x=>x.Version === vCurrentVersion);
            
            if(vIndex > 0) {
                versionsCompare.dsSource1.setCurrentIndex(vIndex);
            }else{
                versionsCompare.dsSource1.setCurrentIndex(0, true);
            }; 
        });

        versionsCompare.dsSource2.setCurrentIndex(pRow.index);
        emit("sourceChange");
    }

    window['dsSource1'] = versionsCompare.dsSource1;
    window['dsSource2'] = versionsCompare.dsSource2
    window['compareToProps'] = props
    window['versionsCompare'] = versionsCompare
</script>